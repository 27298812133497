import { Card, FormControl, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import useUpdateOrganizationInvoicingPaymentTermsNet from "hooks/organization/useUpdateOrganizationInvoicingPaymentTermsNet";

export default function InvoicingPaymentTerms({
  currentValue,
  organizationId,
}: {
  currentValue: string | number;
  organizationId: string;
}) {
  const paymentTermsOptions = [
    { value: "0", label: "Due on Receipt" },
    { value: "10", label: "Net 10" },
    { value: "15", label: "Net 15" },
    { value: "20", label: "Net 20" },
    { value: "30", label: "Net 30" },
    { value: "60", label: "Net 60" },
    { value: "90", label: "Net 90" },
  ];

  const [updatePaymentTerms, { loading }] = useUpdateOrganizationInvoicingPaymentTermsNet();

  const handleChange = (value: string | number) => {
    updatePaymentTerms({
      variables: {
        id: organizationId,
        invoicingPaymentTermsNet: +value,
      },
    });
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDBox mb={2}>
          <MDTypography variant="h5">Invoice Payment Terms</MDTypography>
        </MDBox>
        <MDBox>
          <FormControl fullWidth>
            <MDSelect
              value={currentValue?.toString() || "0"}
              onChange={(e) => handleChange(e.target.value)}
              options={paymentTermsOptions}
              label="Payment Terms"
              name="paymentTerms"
              fullWidth
            />
          </FormControl>
        </MDBox>
      </MDBox>
    </Card>
  );
}
