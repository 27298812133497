import ViewProposalMenuItem from "components/proposals/MenuItems/ViewProposalMenuItem";
import { Proposal } from "generated/graphql";
import { MenuItem } from "@mui/material";
import DuplicateProposal from "../buttons/DuplicateProposal/DuplicateProposal";

export default function VersionMenuItems({
  proposal,
  closeMenu,
  proposalIsWon,
}: {
  proposal: Proposal;
  closeMenu: () => void;
  proposalIsWon: boolean;
}) {
  return (
    <>
      <ViewProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      <MenuItem data-test-id="action-info" onClick={closeMenu}>
        <DuplicateProposal
          id={proposal.id}
          status={proposal.status}
          normalText
          text={proposalIsWon ? "Duplicate Proposal" : "Use Version"}
          variables={{ createNewVersion: !proposalIsWon }}
          redirectRoute="proposals.info"
        />
      </MenuItem>
    </>
  );
}
