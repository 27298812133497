import { useCallback, useContext, useState } from "react";
import StageProductContext from "./forms/StageProductContext";
import { CircularProgress, Icon, IconButton } from "@mui/material";
import useDeleteProposalFile from "hooks/proposals/useDeleteProposalFile";
import { Reference, useApolloClient } from "@apollo/client";
import {
  DeleteProposalFileMutationVariables,
  DeleteProposalMapImageMutationVariables,
  DeleteProposalProductProjectFileMutationVariables,
} from "generated/graphql";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import isImage from "utils/images/isImage";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import useDeleteProposalMapImage from "hooks/proposals/useProposalMapImage";
import useDeleteProposalProductProjectFile from "hooks/proposals/useDeleteProposalProductProjectFile";

enum FileOrigin {
  ProjectFile,
  ProductFile,
  Map,
}

interface File {
  id?: string;
  origin: FileOrigin;
  href: string;
  projectFileId?: string;
}

export default function ProposalProductFiles() {
  const client = useApolloClient();
  const { product } = useContext(StageProductContext);
  const [deleteProposalFile, { getData }] = useDeleteProposalFile();
  const [deleteProposalProductProjectFile, { getData: getProjectFileData }] =
    useDeleteProposalProductProjectFile();
  const [deleteProposalMapImage, { getData: mapImageGetData }] = useDeleteProposalMapImage();
  const [index, setIndex] = useState(-1);

  const { images, regularFiles } = product.files.reduce(
    (acc, productFile) => {
      const fileData: File = {
        id: productFile.id,
        origin: FileOrigin.ProductFile,
        href: `${import.meta.env.VITE_CDN_BASE_URL}/${productFile.filename}`,
      };

      if (isImage(productFile.filename)) {
        acc.images.push(fileData);
      } else {
        acc.regularFiles.push(fileData);
      }

      return acc;
    },
    { images: [] as Array<File>, regularFiles: [] as Array<File> }
  );

  if (product?.mapImage && !product?.mapImageDirty) {
    images.push({
      href: `${import.meta.env.VITE_CDN_BASE_URL}/${product.mapImage}`,
      origin: FileOrigin.Map,
    });
  }

  if (product?.projectFiles && product?.projectFiles.length > 0) {
    product.projectFiles.forEach((projectFile) => {
      const file = {
        href: `${import.meta.env.VITE_CDN_BASE_URL}/${projectFile.path}`,
        origin: FileOrigin.ProjectFile,
        id: projectFile.pivot?.id,
        projectFileId: projectFile.id,
      };
      isImage(projectFile.name) ? images.push(file) : regularFiles.push(file);
    });
  }

  const slides = images.map(({ href }) => {
    return { src: href };
  });

  const removeFileFromCache = useCallback(
    (success, id, field: "files" | "projectFiles") => {
      if (success) {
        const idToRemove = id;
        client.cache.modify({
          id: client.cache.identify(product),
          fields: {
            [field]: (existingFilesRef: Reference[], { readField }) => {
              return existingFilesRef.filter((fileRef) => {
                return idToRemove !== readField("id", fileRef);
              });
            },
          },
        });
      }
    },
    [client]
  );

  const onDeleteMapImage = useCallback(
    async (variables: DeleteProposalMapImageMutationVariables) => {
      const result = await deleteProposalMapImage({
        variables,
      });
      const success = mapImageGetData(result);
      removeFileFromCache(success, variables.id, "files");
    },
    [deleteProposalMapImage, removeFileFromCache]
  );

  const onDelete = useCallback(
    async (variables: DeleteProposalFileMutationVariables) => {
      const result = await deleteProposalFile({
        variables,
      });
      const success = getData(result);
      removeFileFromCache(success, variables.id, "files");
    },
    [deleteProposalFile, removeFileFromCache]
  );

  const onDeleteProjectFile = useCallback(
    async (variables: DeleteProposalProductProjectFileMutationVariables, projectFileId) => {
      const result = await deleteProposalProductProjectFile({
        variables,
      });
      const success = getProjectFileData(result);
      removeFileFromCache(success, projectFileId, "projectFiles");
    },
    [deleteProposalProductProjectFile, removeFileFromCache]
  );

  const handleOnDelete = ({ origin, id, projectFileId }: File) => {
    switch (origin) {
      case FileOrigin.ProductFile:
        return onDelete({
          id,
        });

      case FileOrigin.ProjectFile:
        return onDeleteProjectFile(
          {
            id,
          },
          projectFileId
        );

      case FileOrigin.Map:
        return onDeleteMapImage({
          id: product.id,
        });
    }
  };

  return (
    <div>
      <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} />
      {images.map((file, i) => (
        <div
          key={i}
          style={{
            display: "inline-flex",
            marginRight: "1em",
            marginBottom: "1em",
            position: "relative",
          }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              setIndex(i);
            }}
            href={`${file.href}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <img
              style={{
                borderRadius: "4px",
                width: "23vw",
                height: "23vw",
                objectFit: "cover",
              }}
              src={`${file.href}`}
            />
          </a>
          <a
            style={{
              display: "block",
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: "4px",
            }}
          >
            <IconButton
              onClick={() => {
                handleOnDelete(file);
              }}
            >
              <Icon fontSize="medium" color="error">
                delete
              </Icon>
            </IconButton>
          </a>
        </div>
      ))}

      {product?.mapImageDirty && !product?.mapImage && (
        <MDBox display="flex" gap={5} alignItems="center">
          <MDTypography variant="h6">
            Generating Map Image... (Takes up to 5 mins, but you may continue working)
          </MDTypography>
          <CircularProgress color="info" />
        </MDBox>
      )}
      {regularFiles.map((regularFile, i) => (
        <MDBox key={i} py={1}>
          <MDTypography variant="h6">
            <a
              style={{ textDecoration: "none", color: "#344767" }}
              href={regularFile.href}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {regularFile.href}
            </a>
          </MDTypography>
        </MDBox>
      ))}
    </div>
  );
}
