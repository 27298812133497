import { createContext, useState } from "react";

export interface ProjectFilesContextValue {
  selectedItems: Array<string> | string;
  setSelectedItems: React.Dispatch<React.SetStateAction<Array<string> | string>>;
}

export const ProjectFilesContext = createContext<ProjectFilesContextValue>(
  {} as ProjectFilesContextValue
);

export default function ProjectFilesContextProvider({ children }) {
  const [selectedItems, setSelectedItems] = useState<string | Array<string> | null>(null);

  return (
    <ProjectFilesContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
      }}
    >
      {children}
    </ProjectFilesContext.Provider>
  );
}
