import Tree from "modules/ProjectFiles/Tree";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useGetProjectFiles } from "hooks/projectFiles/useGetProjectFiles";
import ProjectFilesContextProvider from "modules/proposals/providers/ProjectFilesContextProvider";
import FileDetails from "modules/ProjectFiles/FileDetails";

export default function ProjectFileExplorer({
  fileView,
  folderView,
  folderId,
  enableSelectFile = false,
  hideAddFile = false,
  allowMultiSelect = false,
  submitFn,
}: {
  fileView?: boolean;
  folderView?: boolean;
  folderId?: string | number | null;
  enableSelectFile?: boolean;
  hideAddFile?: boolean;
  allowMultiSelect?: boolean;
  submitFn?: (folderId: string | string[]) => void;
}) {
  const { data, loading } = useGetProjectFiles();

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <ProjectFilesContextProvider>
          <MDBox my={3}>
            <Grid container>
              {!fileView && (
                <Grid item xs={12} md={folderView ? 12 : 4}>
                  <Tree
                    data={data}
                    submitFn={submitFn}
                    folderView={folderView}
                    enableSelectFile={enableSelectFile}
                    hideAddFile={hideAddFile}
                    allowMultiSelect={allowMultiSelect}
                  />
                </Grid>
              )}

              {!folderView && !(fileView && !folderId) && (
                <Grid item xs={12} md={fileView ? 12 : 7} ml={3} pl={4}>
                  <FileDetails data={data} fileView={fileView} folderId={folderId} />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </ProjectFilesContextProvider>
      )}
    </>
  );
}
