export const proposalContentTypeName = {
  ACCEPTANCE: "Proposal Acceptance",
  PROJECT_SUMMARY: "Proposal Stage Summary",
  STATEMENT_OF_WORK: "Statement Of Work",
  TERMS: "Terms and Conditions",
  TITLE_PAGE: "Title Page",
  INVOICE_DETAILS: "Invoice Details",
};

export const proposalContentTypeTooltip = {
  ACCEPTANCE: "Proposal Acceptance",
  PROJECT_SUMMARY: "Proposal Stage Summary",
  STATEMENT_OF_WORK: "Statement Of Work",
  TERMS: "Terms and Conditions",
  TITLE_PAGE: "Title Page",
  INVOICE_DETAILS: "Invoice Details",
};
