// @ts-nocheck

import * as React from "react";
import { useMap } from "react-leaflet";
import * as L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon-2x.png";
import { GeomanControls } from "react-leaflet-geoman-v2";

interface Props {
  setMap: (map: L.Map) => void;
  onChange: (e: any) => void;
}

export const NumberedDivIcon = L.Icon.extend({
  options: {
    number: "",
    shadowUrl: null,
    iconSize: new L.Point(0, 0),
    iconAnchor: new L.Point(0, 0),
    popupAnchor: new L.Point(0, -33),
    className: "leaflet-div-icon",
  },

  createIcon: function () {
    var div = document.createElement("div");
    var numdiv = document.createElement("div");
    numdiv.setAttribute("class", "number");
    numdiv.innerHTML = this.options["number"] || "";
    div.appendChild(numdiv);
    this._setIconStyles(div, "icon");
    return div;
  },

  createShadow: function () {
    return null;
  },
});

export const LayerInformationIcon = L.Icon.extend({
  options: {
    content: "",
    color: "",
    shadowUrl: null,
    iconSize: new L.Point(0, 0),
    iconAnchor: new L.Point(0, 0),
    popupAnchor: new L.Point(0, -33),
    className: "leaflet-information-icon",
    onCancel: () => {},
  },

  highlight: function (layer: L.Layer) {
    const content: HTMLDivElement = layer._icon.children[0];
    content.style.borderWidth = "5px";
  },

  unhighlight: function (layer: L.Layer) {
    const content: HTMLDivElement = layer._icon.children[0];
    content.style.borderWidth = "3px";
  },

  createIcon: function () {
    var div = document.createElement("div");
    var contentdiv = document.createElement("div");
    var cancelIcon = document.createElement("div");
    cancelIcon.setAttribute("class", "cancel");
    contentdiv.setAttribute("class", "content");
    cancelIcon.innerHTML = "x";
    cancelIcon.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.options["onCancel"]();
    });
    contentdiv.innerHTML = this.options["content"] || "";
    contentdiv.style.borderColor = this.options["color"] || "";
    cancelIcon.style.borderColor = this.options["color"] || "";
    div.appendChild(contentdiv);

    if (this.options["onCancel"]) {
      div.appendChild(cancelIcon);
    }
    this._setIconStyles(div, "icon");
    return div;
  },

  createShadow: function () {
    return null;
  },
});

// @ts-ignore
export const ColoredDivIcon = L.Icon.extend({
  options: {
    className: "leaflet-custom-marker-icon",
    iconSize: [32, 50],
    iconAnchor: [16, 50],
    color: "blue",
  },

  changeColor: function (color: string, layer: L.Layer) {
    this.options.color = color;
    const icon: HTMLDivElement = layer._icon;
    icon.getElementsByTagName("path")[0].style.fill = color;
    icon.getElementsByTagName("circle")[0].style.fill = color;
  },

  highlight: function (layer: L.Layer) {
    const icon: HTMLDivElement = layer._icon;
    icon.getElementsByTagName("path")[0].style.strokeWidth = "8px";
  },

  unhighlight: function (layer: L.Layer) {
    const icon: HTMLDivElement = layer._icon;
    icon.getElementsByTagName("path")[0].style.strokeWidth = "0px";
  },

  createIcon: function () {
    var div = document.createElement("div"),
      options = this.options;

    div.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.44098 293.33429" style="overflow: visible;">
    <g>
        <path style="fill: ${options.color}; stroke: #fff; stroke-width: 0" class="cls-1"
              d="M93.721,0a93.64081,93.64081,0,0,0-72.83,152.599c4.204,5.178,11.237,13.331,14.903,18.906,21.109,32.069,48.19,78.643,56.082,116.864,1.354,6.527,2.986,6.641,4.743.212a422.6757,422.6757,0,0,1,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379a94.5609,94.5609,0,0,0,16.154-24.084A93.57024,93.57024,0,0,0,93.721,0Zm0,144.358a52.31,52.31,0,1,1,52.313-52.313A52.31259,52.31259,0,0,1,93.721,144.358Z"/>
        <circle style="fill: ${options.color}" class="cls-1" cx="93.72101" cy="90.196" r="21.756"/>
    </g>
</svg>`;

    this._setIconStyles(div, "icon");

    return div;
  },

  createShadow: function () {
    return null;
  },
});

L.PM.Draw.Polygon.prototype._setTooltipText = function () {};
L.PM.Draw.Line.prototype._setTooltipText = function () {};

export default function Geoman({ setMap, onChange }: Props) {
  const map = useMap();
  React.useEffect(() => {
    const resizeHandler = () => {
      map?.fitBounds(map?.getBounds());
    };

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
    window.addEventListener("resize", resizeHandler);

    setMap(map);
  }, []);

  return (
    <GeomanControls
      options={{
        position: "topleft",
        drawControls: false,
        editControls: false,
      }}
      globalOptions={{
        continueDrawing: true,
        editable: false,
        tooltips: false,
        cursorMarker: false,
      }}
      onCreate={onChange}
    />
  );
}
