import {
  GetInvoicesSortKey,
  JobStatus,
  SortDirection,
  InvoiceStatus,
  JobBillingStatus,
} from "generated/graphql";
import dayjs from "dayjs";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { Link } from "react-router-dom";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useGetInvoices } from "hooks/organization/invoices/useGetInvoices";
import { capitalCase } from "change-case";
import MDTypography from "components/MDTypography";
import { sortDirectionMap } from "constants/sortDirectionMap";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import SingleChoiceFilter from "components/filters/SingleChoiceFilter/SingleChoiceFilter";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import JobModal from "components/JobModal/JobModal";

export const invoicesSortKeyMap = {
  id: "ID",
  status: "STATUS",
  sentAt: "SENT_AT",
};

export const invoicesInitialSortDirectionKey = "desc";
export const invoicesInitialSortDirectionValue = SortDirection.DESC;
export const invoicesInitialSortKeyKey = "id";
export const invoicesInitialSortKeyValue = GetInvoicesSortKey.ID;

export default function useInvoicesTable() {
  let savedFilters = JSON.parse(localStorage.getItem("useInvoicesTableFilters")) ?? null;

  const { sorting, offset, filtering } = usePaginatedVariables({
    initialSearchTerm: savedFilters?.searchTerm ?? "",
    initialSortKey: invoicesInitialSortKeyKey,
    initialSortDirection: invoicesInitialSortDirectionKey,
    initialCompanyId: "",
    initialMultipleCompanyIds: savedFilters?.multipleCompanyIDs ?? [],
    initialStatuses: savedFilters?.statuses ?? [],
    initialInvoiceStatus: savedFilters?.invoiceStatus ?? null,
    initialIsOverdue: savedFilters?.isOverdue ?? null,
    initialBillingStatuses: savedFilters?.billingStatuses ?? [],
  });

  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies(true);

  useEffect(() => {
    localStorage.setItem(
      "useInvoicesTableFilters",
      JSON.stringify({
        searchTerm: filtering.searchTerm,
        companyId: filtering.companyId,
        statuses: filtering.statuses,
        invoiceStatus: filtering.invoiceStatus,
        isOverdue: filtering.isOverdue,
        billingStatuses: filtering.billingStatuses,
      })
    );
  }, [
    filtering.searchTerm,
    filtering.companyId,
    filtering.statuses,
    filtering.invoiceStatus,
    filtering.isOverdue,
    filtering.billingStatuses,
  ]);

  const params = {
    page: offset.page,
    first: offset.first,
    sortKey: invoicesSortKeyMap[sorting.sortKey] || invoicesInitialSortKeyValue,
    sortDirection: sortDirectionMap[sorting.sortDirection] || invoicesInitialSortDirectionValue,
    search: filtering.debouncedSearchTerm,
    companyId: filtering.companyId || undefined,
    multipleCompanyIDs: filtering.multipleCompanyIDs || undefined,
    ...(filtering.isOverdue !== null && { isOverdue: filtering.isOverdue }),
    ...(filtering.statuses.length > 0 && { jobStatuses: filtering.statuses as JobStatus[] }),
    ...(filtering.invoiceStatus !== null && { status: filtering.invoiceStatus as InvoiceStatus }),
    ...(filtering.billingStatuses.length > 0 && {
      billingStatuses: filtering.billingStatuses as JobBillingStatus[],
    }),
  };

  const { data, loading, error, paginatorInfo, fetchMore } = useGetInvoices({
    variables: params,
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const handleSearch = useCallback(
    (term: string) => {
      filtering.setSearchTerm(term);
    },
    [filtering]
  );

  const handleCompanyChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleCompanyIds(selectedOptions);
    },
    [filtering]
  );

  const handleBillingStatusesChecked = useCallback(
    (selectedOptions) => {
      return filtering.setBillingStatuses(selectedOptions);
    },
    [filtering]
  );

  const companyFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.multipleCompanyIDs}
          loading={companiesLoading}
          error={companiesError}
          options={
            companiesData?.map((company) => ({ label: company.name, value: company.id })) ?? []
          }
          onChange={handleCompanyChecked}
        />
      );
    },
    [
      filtering.companyId,
      filtering.multipleCompanyIDs,
      companiesData,
      companiesLoading,
      companiesError,
    ]
  );

  const invoiceStatusFilter = useCallback(
    ({ column: { id } }) => {
      const options = enumToValueOptions(InvoiceStatus);

      return (
        <SingleChoiceFilter
          loading={false}
          error={null}
          id={id}
          value={filtering.invoiceStatus}
          options={options}
          onChange={(selectedStatus) =>
            filtering.setInvoiceStatus((prevState) =>
              prevState === selectedStatus ? null : selectedStatus
            )
          }
        />
      );
    },
    [filtering]
  );

  const overdueFilter = useCallback(
    ({ column: { id } }) => {
      const options = [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ];

      return (
        <SingleChoiceFilter
          loading={false}
          error={null}
          id={id}
          value={filtering.isOverdue !== null ? (filtering.isOverdue ? "true" : "false") : null}
          options={options}
          onChange={(selectedValue) => {
            if (selectedValue === null) {
              filtering.setIsOverdue(null);
            } else {
              filtering.setIsOverdue(selectedValue === "true");
            }
          }}
        />
      );
    },
    [filtering]
  );

  const billingStatusFilter = useCallback(
    ({ column: { id } }) => {
      const options = enumToValueOptions(JobBillingStatus);

      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.billingStatuses}
          loading={false}
          error={null}
          options={options}
          onChange={handleBillingStatusesChecked}
        />
      );
    },
    [filtering, handleBillingStatusesChecked]
  );

  const [openModal, setOpenModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);

  const handleOpenModal = useCallback((jobId: string) => {
    setSelectedJobId(jobId);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedJobId(null);
    setOpenModal(false);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Company Name",
        accessor: "job.company.name",
        Cell: ({ value }) => value || "N/A",
        Filter: companyFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Job Name",
        accessor: "job.proposalStage.name",
        Cell: ({ row, value }) => (
          <Link
            style={{ color: "inherit" }}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal(row.original.job.id);
            }}
          >
            {value}
          </Link>
        ),
        disableSortBy: true,
      },
      {
        Header: "Job Status",
        accessor: "job.status",
        Cell: ({ value }) => capitalCase(value ?? "N/A"),
        disableSortBy: true,
      },
      {
        Header: "Billing Status",
        accessor: "job.billingStatus",
        Cell: ({ value }) => capitalCase(value ?? ""),
        Filter: billingStatusFilter,
        filter: "includes",
        disableSortBy: false,
      },
      {
        Header: "Sent Date",
        accessor: "sentAt",
        Cell: ({ value }) => (value ? dayjs(value).format("MM/DD/YYYY") : "Not Sent"),
      },
      {
        Header: "Overdue",
        accessor: "isOverdue",
        Cell: ({ value }) => (
          <MDTypography variant="caption" color={value ? "error" : "success"} fontWeight="medium">
            {value ? "Yes" : "No"}
          </MDTypography>
        ),
        Filter: overdueFilter,
        filter: "equals",
        disableSortBy: true,
      },
    ];
  }, [companyFilter, invoiceStatusFilter, overdueFilter, billingStatusFilter, handleOpenModal]);

  const tableData = useMemo(() => {
    return {
      columns,
      rows: data || [],
      modal: <JobModal jobId={selectedJobId} open={openModal} onClose={handleCloseModal} />,
    };
  }, [columns, data, selectedJobId, openModal, handleCloseModal]);

  return {
    columns,
    data,
    loading,
    error,
    pagination,
    fetchMore,
    filtering,
    handleSearch,
    tableData,
    paginatorInfo,
    sorting,
  } as const;
}
