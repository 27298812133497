import getLocalString from "constants/Localization";
import { useDeleteProposalProductProjectFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProposalProductProjectFile() {
  const [mutation, { loading, data, error }] = useDeleteProposalProductProjectFileMutation();
  const [deleteProposalProductProjectFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProposalProductProjectFile",
    },
    {
      successMessage: getLocalString("proposals.delete-proposal-product-project-file-success"),
      failureMessage: getLocalString("proposals.delete-proposal-product-project-file-failure"),
    }
  );

  return [deleteProposalProductProjectFile, context] as const;
}
