import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Index from "modules/OrganizationAddresses/Index";
import OrganizationSettings from "modules/OrganizationSettings/OrganizationSettings";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useGetOrganization from "hooks/organization/useGetOrganization";
import OrganizationDocuments from "modules/organization/UpdateOrganization/OrganizationDocuments";
import OrganizationInfo from "modules/organization/UpdateOrganization/OrganizationInfo";
import { Organization, OrganizationLinkedServiceName } from "generated/graphql";
import OrganizationSalesTaxForm from "modules/organization/OrganizationSalesTax/OrganizationSalesTax";
import ConversionFactorOverride from "modules/organization/ConversionFactorOverride/ConversionFactorOverride";
import InvoicingPaymentTerms from "modules/organization/InvoicingPaymentTerms/InvoicingPaymentTerms";

const OrganizationSettingsPage = () => {
  const { data } = useGetOrganization();

  const hasLinkedQuickbooks = !!data?.linkedServices?.find(
    (service) => service.service === OrganizationLinkedServiceName.QUICKBOOKS && service.active
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Organization settings
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="organization-address">
          <MDBox display="flex" alignItems="center" justifyContent={"space-between"} width="100%">
            <MDTypography variant="h5">Update Organization</MDTypography>
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          {data && (
            <>
              <OrganizationInfo organization={data as Organization} />
              <OrganizationDocuments organization={data as Organization} />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ display: "flex", "& > *": { width: "100%" } }}>
            <OrganizationSettings />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex", "& > *": { width: "100%" } }}>
            <InvoicingPaymentTerms
              currentValue={data?.invoicingPaymentTermsNet ?? "0"}
              organizationId={data?.id}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox my={3}>
        <Card>
          <MDTypography variant="h5" p={3}>
            Organization Tax Rates
          </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {data && (
                <OrganizationSalesTaxForm
                  salesTaxes={data.salesTaxes}
                  hasLinkedQuickbooks={hasLinkedQuickbooks}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox my={3}>
        {data && <ConversionFactorOverride organization={data as Organization} />}
      </MDBox>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="organization-address">
          <MDBox display="flex" alignItems="center" justifyContent={"space-between"} width="100%">
            <MDTypography variant="h5" p={1}>
              Organization Addresses
            </MDTypography>

            <MDTypography as={Link} to={getRoute("organization.create-address")}>
              <MDButton color="blue">
                <Icon>add</Icon>Create Address
              </MDButton>
            </MDTypography>
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Index />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Footer />
    </DashboardLayout>
  );
};

export default OrganizationSettingsPage;
