import { useUpdateOrganizationInvoicingPaymentTermsNetMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import getLocalString from "constants/Localization";

const useUpdateOrganizationInvoicingPaymentTermsNet = () => {
  const [mutation, { loading, data, error }] =
    useUpdateOrganizationInvoicingPaymentTermsNetMutation({
      refetchQueries: ["getOrganization"],
    });

  const [updateOrganizationInvoicingPaymentTermsNet, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "updateOrganizationInvoicingPaymentTermsNet",
    },
    {
      successMessage: getLocalString("organization.update-invoice-payment-terms-success"),
      failureMessage: getLocalString("organization.update-invoice-payment-terms-failure"),
    }
  );

  return [updateOrganizationInvoicingPaymentTermsNet, context] as const;
};

export default useUpdateOrganizationInvoicingPaymentTermsNet;
