import getLocalString from "constants/Localization";
import { useUpdateProposalProductProjectFilesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateProposalProductProjectFiles() {
  const [mutation, { loading, data, error }] = useUpdateProposalProductProjectFilesMutation();
  const [updateProposalProductProjectFiles, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateProposalProductProjectFiles",
    },
    {
      successMessage: getLocalString("proposals.update-proposal-product-project-files-success"),
      failureMessage: getLocalString("proposals.update-proposal-product-project-files-failure"),
    }
  );

  return [updateProposalProductProjectFiles, context] as const;
}
