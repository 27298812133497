import { Box, Grid, Typography, FormControlLabel, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import QuickbooksEditableInformation from "../QuickbooksEditableInformation";
import JobsDetailsSummary from "../JobsDetailsSummary";
import {
  Proposal,
  Job,
  Organization,
  SaveQuickbooksIntegrationInput,
  Invoice,
} from "generated/graphql";
import { useState } from "react";
import useSendInvoice from "hooks/organization/invoices/useSendInvoice";
import usePreviewInvoice from "hooks/organization/invoices/usePreviewInvoice";
import Modal from "modules/Modal/Modal";

interface SendInvoiceModalProps {
  open: boolean;
  onClose: () => void;
  proposalId: Proposal["id"];
  job: Job;
  organization: Organization;
  invoice?: Invoice | null;
  loading?: boolean;
}

export default function SendInvoiceModal({
  open,
  onClose,
  proposalId,
  job,
  organization,
  invoice,
  loading,
}: SendInvoiceModalProps) {
  const [formData, setFormData] = useState<SaveQuickbooksIntegrationInput | null>(null);
  const [sendInvoice, { loading: sendInvoiceLoading }] = useSendInvoice();
  const [previewInvoice, { loading: previewInvoiceLoading }] = usePreviewInvoice();
  const [hideLineItems, setHideLineItems] = useState(false);

  const validateFormData = () => {
    if (!formData) return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.customerEmail)) {
      return false;
    }
    const numberRegex = /^[0-9]*$/;
    if (
      !numberRegex.test(formData.customerBillingAddressZip) ||
      !numberRegex.test(formData.shipAddressAddressZip)
    ) {
      return false;
    }
    return true;
  };

  const handleSendInvoice = async () => {
    if (!validateFormData()) return;

    await sendInvoice({
      variables: {
        id: invoice.id,
        data: JSON.stringify(formData),
        hideLineItemPricing: hideLineItems,
      },
    });
    onClose();
  };

  const handlePreviewInvoice = async () => {
    if (!validateFormData()) return;

    const result = await previewInvoice({
      variables: {
        id: invoice.id,
        hideLineItemPricing: hideLineItems,
        data: JSON.stringify(formData),
      },
    });

    if (result?.data?.previewInvoice) {
      window.open(result.data.previewInvoice, "_blank");
    }
  };

  return (
    <Box style={{ padding: "2em 0.5em" }}>
      <Typography variant="h4" align="center" mb={3}>
        Confirm Invoice Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={7} xs={12}>
          <QuickbooksEditableInformation
            proposalId={proposalId}
            job={job}
            organization={organization}
            onChange={(data) => setFormData(data)}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <JobsDetailsSummary proposalId={proposalId} job={job} />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, gap: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={hideLineItems}
              onChange={(e) => setHideLineItems(e.target.checked)}
              color="primary"
            />
          }
          label="Hide line items"
        />
        <MDButton
          onClick={handlePreviewInvoice}
          disabled={previewInvoiceLoading || !validateFormData()}
        >
          Preview Invoice
        </MDButton>
        <MDButton
          color="success"
          onClick={handleSendInvoice}
          disabled={sendInvoiceLoading || !validateFormData()}
        >
          Send Invoice
        </MDButton>
      </Box>
    </Box>
  );
}
