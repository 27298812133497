import getLocalString from "constants/Localization";
import { useSendInvoiceMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSendInvoice() {
  const [mutation, { loading, error, data }] = useSendInvoiceMutation({
    refetchQueries: ["getInvoice", "customGetJob"],
  });

  const [sendInvoice, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "sendInvoice",
    },
    {
      successMessage: getLocalString("organization.send-invoice-success"),
      failureMessage: getLocalString("organization.send-invoice-failure"),
      showValidationErrors: true,
    }
  );

  return [sendInvoice, context] as const;
}
