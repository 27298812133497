import MDBox from "components/MDBox";
import {
  ProposalDefault,
  ProposalLayout,
  UpdateOrganizationProposalDefaultsInput,
} from "generated/graphql";
import { Checkbox, Divider, FormControlLabel, FormLabel, Grid, Paper } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useUpdateOrganizationProposalDefaults from "hooks/organization/useUpdateOrganizationProposalDefaults";
import { useCallback, useState } from "react";
import MDButton from "components/MDButton";
import { CompactPicker } from "react-color";
import reactCSS from "reactcss";
import { ColorPickerProps, colors } from "modules/OrganizationCrews/Create";
import { convertHexToRGB } from "utils/object/getRandomColor";
import "react-datepicker/dist/react-datepicker.css";
import FormField from "components/FormField/FormField";

const schema = z.object({
  id: z.string().min(1, "ID is required"),
  layout: z.nativeEnum(ProposalLayout),
  removeUnitPriceAndQuantity: z.boolean().optional(),
  removeIndividualLineItemPricing: z.boolean().optional(),
  color: z.string().optional().or(z.null()),
  startingNumber: z.number().optional().or(z.null()),
  idPrefix: z
    .string()
    .max(6, "Prefix must be 6 characters or less")
    .regex(/^[a-zA-Z0-9]*$/, "Prefix must be alphanumeric")
    .optional()
    .or(z.null()),
});

export default function ProposalDefaults({
  proposalDefaults,
}: {
  proposalDefaults: ProposalDefault;
}) {
  const defaultValues = {
    id: proposalDefaults.id,
    color: proposalDefaults.color,
    layout: proposalDefaults.layout,
    removeUnitPriceAndQuantity: proposalDefaults.removeUnitPriceAndQuantity,
    removeIndividualLineItemPricing: proposalDefaults.removeIndividualLineItemPricing,
    startingNumber: proposalDefaults.startingNumber || null,
    idPrefix: proposalDefaults.idPrefix || null,
  };

  const methods = useForm<UpdateOrganizationProposalDefaultsInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [updateOrganizationProposalDefault] = useUpdateOrganizationProposalDefaults();

  const defaultColor = proposalDefaults.color || "E9C647";
  const [colorPicker, setColorPicker] = useState<ColorPickerProps>({
    displayColorPicker: false,
    color: {
      hex: defaultColor,
      rgb: convertHexToRGB(defaultColor),
    },
  });

  const onSubmit = useCallback(
    (input) => {
      updateOrganizationProposalDefault({
        variables: {
          input: {
            ...input,
            color:
              colorPicker.color.hex[0] === "#"
                ? colorPicker.color.hex.substring(1)
                : colorPicker.color.hex.substring(0),
          },
        },
      });
    },
    [updateOrganizationProposalDefault, colorPicker]
  );

  const handleClick = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: !colorPicker.displayColorPicker });
  };

  const handleClose = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setColorPicker({
      ...colorPicker,
      color: {
        hex: color.hex,
        rgb: convertHexToRGB(color.hex),
      },
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${colorPicker.color?.rgb?.r}, ${colorPicker.color?.rgb?.g}, ${colorPicker.color?.rgb?.b}, 1)`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        maxWidth: "45px",
        marginTop: "20px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleFormatSelection = (e, value: ProposalLayout) => {
    e.preventDefault();
    methods.setValue("layout", value);
  };

  // eslint-disable-next-line no-console
  console.log(methods.formState.errors);

  const selectedFormat = methods.watch("layout");

  return (
    <Paper sx={{ p: "25px", mb: "20px" }}>
      <MDBox component="form" role="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <MDTypography>Proposal Defaults</MDTypography>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={6} lg={4}>
            <MDTypography variant="h6" mt={1}>
              Proposal Format
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{
                  backgroundColor: selectedFormat === ProposalLayout.BRANDED ? "#af9" : "#eee",
                }}
                mr={1}
                textAlign="center"
              >
                <a href="" onClick={(e) => handleFormatSelection(e, ProposalLayout.BRANDED)}>
                  <img src="/assets/images/branded_proposal.png" width={144} />
                  <MDTypography variant="h6" mt={1}>
                    Branded
                  </MDTypography>
                </a>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{ backgroundColor: selectedFormat === ProposalLayout.SIMPLE ? "#af9" : "#eee" }}
                mr={1}
                textAlign="center"
              >
                <a href="" onClick={(e) => handleFormatSelection(e, ProposalLayout.SIMPLE)}>
                  <img src="/assets/images/simple_proposal.png" width={144} />
                  <MDTypography variant="h6" mt={1}>
                    Simple
                  </MDTypography>
                </a>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{ backgroundColor: selectedFormat === ProposalLayout.FULL ? "#af9" : "#eee" }}
                textAlign="center"
              >
                <a href="" onClick={(e) => handleFormatSelection(e, ProposalLayout.FULL)}>
                  <img src="/assets/images/full_proposal.png" width={144} />
                  <MDTypography variant="h6" mt={1}>
                    Full
                  </MDTypography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={6} lg={4} p={0} sx={{ display: "flex", alignItems: "flex-end" }}>
            <MDTypography variant="h6">ID Configuration</MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormField
                  label="Starting Number"
                  {...methods.register("startingNumber", { valueAsNumber: true })}
                  error={methods.formState.errors.startingNumber}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  label="ID Prefix"
                  {...methods.register("idPrefix")}
                  error={methods.formState.errors.idPrefix}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <MDTypography variant="h6" mt={2.5}>
              Proposal Color
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <div style={styles.swatch} onClick={handleClick} id="colorpicker">
              <div style={styles.color} />
            </div>
            {colorPicker.displayColorPicker ? (
              // @ts-expect-error
              <div style={styles.popover}>
                {/* @ts-expect-error */}
                <div style={styles.cover} onClick={handleClose} />
                <CompactPicker
                  color={colorPicker.color?.hex}
                  onChange={handleChange}
                  colors={colors}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Divider />

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="removeUnitPriceAndQuantity"
                  control={methods.control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Remove Unit Price And Quantity"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="removeIndividualLineItemPricing"
                  control={methods.control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Remove Individual Line Item Pricing"
            />
          </Grid>
          <Grid item xs={12} mt={2} display="flex" justifyContent="space-between">
            <MDButton type="submit" variant="gradient" color="success">
              Save
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Paper>
  );
}
