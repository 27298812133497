import { gql } from "@apollo/client";

const proposalProductFragment = gql`
  fragment MyProposalProduct on ProposalProduct {
    additionalCosts {
      cost
      costOverride
      feeAmount
      feeAmountOverride
      feeType
      id
      name
      costTotal
    }
    jobDurationOverride
    jobDurationDaysOverride
    roundTripTravelTimeHours
    costAdditional
    costDirect
    costEquipment
    costIndirect
    costLabour
    costMaterial
    costOverhead
    costTotal
    crews {
      cost
      id
      name
      rate
      rateOverride
      timeOnjob
      timeOnjobOverride
      timeTravel
      timeTravelOverride
    }
    equipmentPieces {
      chargeType
      cost
      id
      name
      quantity
      quantityOverride
      rate
      rateOverride
      timeOnjob
      timeOnjobOverride
      timeTravel
      timeTravelOverride
    }
    files {
      createdAt
      filename
      id
      thumbnail
    }
    projectFiles {
      id
      name
      path
      pivot {
        id
      }
    }
    grossMargin
    grossMarginPercentage
    id
    jobDuration
    jobSize
    mapData
    mapImage
    mapImageDirty

    material {
      areaCoverage
      attributes {
        __typename
        ... on ProductAttributeAsphalt {
          applicationRate
          transferTruckLoadTime
          transferTruckUnloadTime
        }
        ... on ProductAttributeConcreteCurbOrGutter {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeConcreteFlatwork {
          applicationRate
        }
        ... on ProductAttributeConcreteWaterway {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeCrackSeal {
          crackDepth
          crackWidth
          overbandThickness
          overbandWidth
          poundsPerBlock
          poundsPerGallon
        }
        ... on ProductAttributeCurbWall {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeExcavateOut {
          applicationRate
        }
        ... on ProductAttributeGrading {
          applicationRate
          poundsPerCubicYard
        }
        ... on ProductAttributeMastic {
          crackDepth
          crackWidth
          overbandThickness
          overbandWidth
          poundsPerBlock
          poundsPerGallon
        }
        ... on ProductAttributeMicrosurfacing {
          applicationRate
        }
        ... on ProductAttributeSealCoat {
          applicationRate
        }
        ... on ProductAttributeSlurrySeal {
          applicationRate
        }
        ... on ProductAttributeStriping {
          applicationRate
          lineWidth
        }
        ... on ProductAttributeConcreteFooting {
          width
          depth
        }
        ... on ProductAttributeConcreteWall {
          height
          width
        }
        ... on ProductAttributeConcreteColumn {
          height
          diameter
        }
      }
      attributesOverride {
        __typename
        ... on ProductAttributeAsphalt {
          applicationRate
          transferTruckLoadTime
          transferTruckUnloadTime
        }
        ... on ProductAttributeConcreteCurbOrGutter {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeConcreteFlatwork {
          applicationRate
        }
        ... on ProductAttributeConcreteWaterway {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeCrackSeal {
          crackDepth
          crackWidth
          overbandThickness
          overbandWidth
          poundsPerBlock
          poundsPerGallon
        }
        ... on ProductAttributeCurbWall {
          curbHeight
          curbWidth
          gutterHeight
          gutterWidth
        }
        ... on ProductAttributeExcavateOut {
          applicationRate
        }
        ... on ProductAttributeGrading {
          applicationRate
          poundsPerCubicYard
        }
        ... on ProductAttributeMastic {
          crackDepth
          crackWidth
          overbandThickness
          overbandWidth
          poundsPerBlock
          poundsPerGallon
        }
        ... on ProductAttributeMicrosurfacing {
          applicationRate
        }
        ... on ProductAttributeSealCoat {
          applicationRate
        }
        ... on ProductAttributeSlurrySeal {
          applicationRate
        }
        ... on ProductAttributeStriping {
          applicationRate
          lineWidth
        }
        ... on ProductAttributeConcreteFooting {
          width
          depth
        }
        ... on ProductAttributeConcreteWall {
          height
          width
        }
        ... on ProductAttributeConcreteColumn {
          height
          diameter
        }
      }
      costMaterial
      costMaterialOverride
      costMinimumProduct
      costMinimumProposal
      dailyUnitsComplete
      dailyUnitsCompleteOverride
      id
      indirectPercentage
      labourCostType
      markupPercentage
      materialNeeded
      materialNeededOverride
      measurementJob
      measurementMaterial
      organizationProductTypeName
      overheadPercentage
      productId
      proposalId
      proposalProductId
      proposalStageId
      statementOfWork
      statementOfWorkOverride
      tonConversionFactor
      tonConversionFactorOverride
      unitPriceProduct
      unitPrice
      unitPriceOverride
    }
    netMargin
    netMarginPercentage
    operatingMargin
    operatingMarginPercentage
    overallTotal
    plantProduct {
      id
      plant {
        addressCity
        addressCountry
        addressLine1
        addressLine2
        addressState
        addressZip
        distanceMeters
        distanceSeconds
        id
        name
        phone
        plantId
        rateOnWeight
        roundTripSeconds
      }
      plantProductId
      rate
      rateOverride
      type
    }
    profit
    profitPercentage
    transferTruckLoadTimeBuffer
    transferTruckRoundTripTime
    transferTruckUnloadTimeBuffer
    transferTrucks {
      id
      name
      plantTransferTruckId
      quantity
      rateHourly
      rateHourlyOverride
      rateTon
      rateTonOverride
      tonnage
      transferTruckId
      totalHaulingHours
      totalHaulingHoursOverride
      cost
      cost_override
    }
  }
`;

export default proposalProductFragment;
