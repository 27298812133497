import { ProjectFile, ProjectFileType } from "generated/graphql";

export function validateFileSelection(ids: string[], data: Array<ProjectFile>): string[] {
  const getFiles = (id: string, node: any, foundNodeIds: Set<string>): string[] => {
    if (node.id === id && node.type === ProjectFileType.FILE) {
      return [node.id];
    }

    return (node.children || []).flatMap((child: any) => getFiles(id, child, foundNodeIds));
  };

  return ids.flatMap((id) => data.flatMap((file) => getFiles(id, file, new Set())));
}
