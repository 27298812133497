import MDTypography from "components/MDTypography";

import { useCallback, useState } from "react";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import Modal from "modules/Modal/Modal";
import ProjectFileExplorer from "modules/ProjectFiles/ProjectFileExplorer";
import MDBox from "components/MDBox";
import useUpdateProposalProductProjectFiles from "hooks/proposals/useUpdateProposalProductProjectFiles";

export default function AddProjectFileToProposal({ id }: { id: string }) {
  const [projectFileExplorerOptions, setProjectFileExplorerOptions] = useState({
    folderView: false,
    fileView: false,
    fileId: "",
  });
  const {
    open: projectFilesModalOpen,
    onClose: onCloseProjectFilesModal,
    onOpen: onOpenProjectFilesModal,
  } = useModalState();

  const [updateProposalProductProjectFiles] = useUpdateProposalProductProjectFiles();

  const handleProjectFilesFolderSelect = useCallback((fileId: string | Array<string>) => {
    handleProjectFiles(fileId);
    onCloseProjectFilesModal();
  }, []);

  const handleProjectFiles = useCallback(
    (projectFileId: string | Array<string>) => {
      updateProposalProductProjectFiles({
        variables: {
          projectFileIds: projectFileId,
          proposalProductId: id,
        },
      });
    },
    [id]
  );

  return (
    <MDBox>
      <MDButton
        type="button"
        color="blue"
        onClick={() => {
          setProjectFileExplorerOptions({
            folderView: true,
            fileView: false,
            fileId: "",
          });
          onOpenProjectFilesModal();
        }}
      >
        <AddCircleOutlineOutlined />{" "}
        <MDTypography variant="body2" ml={2} color="#FFF">
          Add file from project files
        </MDTypography>
      </MDButton>
      <Modal open={projectFilesModalOpen} onClose={onCloseProjectFilesModal}>
        <MDTypography variant="h4" p={3} pb={0}>
          {projectFileExplorerOptions.fileView ? "View Files" : " Select Project Files Folder"}
        </MDTypography>
        <ProjectFileExplorer
          folderView={projectFileExplorerOptions.folderView}
          fileView={projectFileExplorerOptions.fileView}
          folderId={projectFileExplorerOptions.fileId}
          enableSelectFile={true}
          hideAddFile={true}
          allowMultiSelect={true}
          submitFn={(lastSelectedItem) => {
            handleProjectFilesFolderSelect(lastSelectedItem);
            onCloseProjectFilesModal();
          }}
        />
      </Modal>
    </MDBox>
  );
}
