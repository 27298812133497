import { AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  UpdateProposalProductInputSchema,
  getDefaultValues,
} from "DDD/action-objects/StageProductUpdate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  ProductMeasurementJob,
  ProposalProduct,
  ProposalStage,
  UpdateProposalProductInput,
} from "generated/graphql";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import MDButton from "components/MDButton";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useUpdateProposalProduct from "hooks/proposals/useUpdateProposalProduct";
import { evolve } from "ramda";
import StageProductContext from "./forms/StageProductContext";
import EquipmentCostsSubForm from "./forms/subforms/EquipmentCostsSubForm";
import LabourCostsSubForm from "./forms/subforms/LabourCostsSubForm";
import AdditionalCostsSubForm from "./forms/subforms/AdditionalCostsSubForm";
import { ProposalContext } from "./providers/ProposalContextProvider";
import MaterialCosts from "./MaterialCosts";
import { ProposalFormsContext } from "./providers/ProposalFormsContextProvider";
import measurementJobTranslations from "constants/measurementJobTranslations";
import ProposalDefaultsAndInputs from "./ProposalDefaultsAndInputs";
import useModalState from "hooks/useModalState";
import MapModal from "modules/Mapmodal/MapModal";
import measurementMapTranslations from "constants/measurementMapTranslation";
import ProposalProductFiles from "./ProposalProductFiles";
import { MapOutput } from "interfaces/interfaces";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import getLocalString from "constants/Localization";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import DirtyFieldsTracker from "./DirtyFieldsTracker";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import DeleteStageProduct from "./buttons/Stage/DeleteStageProduct";
import AddProjectFileToProposal from "./AddProjectFileToProposal";

export interface StageProductProps {
  product: ProposalProduct;
  stage: ProposalStage;
  transferTrucks: ProposalProduct["transferTrucks"];
  expandedProduct: ProposalProduct["id"] | false;
  mapData?: string;
  handleDelete?: (id: string) => void;
}

const StageProduct = React.memo(function StageProductContainer({
  product,
  stage,
  transferTrucks,
  expandedProduct,
  mapData,
  handleDelete,
}: StageProductProps) {
  const { show } = useGlobalMessage();
  const { inViewMode } = useContext(ProposalContext);
  const mapImageInterval = useRef<NodeJS.Timer | null>(null);
  const { setProductSubmitFunction, setProductDirtyStatus, setProductFormStatus } =
    useContext(ProposalFormsContext);
  const [updateProposalProduct, { loading, isSuccess, getData }] = useUpdateProposalProduct();
  const { open, onOpen, onClose } = useModalState();
  const [mapDataProduct, setMapDataProduct] = useState(product.mapData ? product.mapData : mapData);
  const isSubcontracted = product.material.subcontracted;
  const measurementJobUnit = product.material.measurementJob === ProductMeasurementJob.UNIT;
  const isUnitPriced = product.material.unitPriceProduct;
  const { data } = useGetOrganizationSettings();
  const averageWorkingHoursPerDay = +data?.["average_working_hours_per_day"] ?? 0;

  const defaultValues: UpdateProposalProductInputSchema = useMemo(() => {
    return getDefaultValues({
      product,
      transferTrucks,
      averageWorkingHoursPerDay,
    });
  }, [product, transferTrucks]);

  const methods = useForm<UpdateProposalProductInputSchema>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues,
  });

  const {
    register,
    control,
    getValues,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const convertInputHoursToSeconds = useCallback((field) => {
    if (field === 0) {
      return 0;
    }
    return field ? Math.floor(field * 3600) : null;
  }, []);

  const onSubmit = useCallback(
    async (input: UpdateProposalProductInput) => {
      (document.activeElement as HTMLElement).blur();
      const inputWithTransformedNullValues = evolve({
        material: (material) => ({
          ...material,
          dailyUnitsComplete: isNaN(material.dailyUnitsComplete)
            ? null
            : material.dailyUnitsComplete?.toString(),
        }),
        transferTrucks: (transferTrucks) =>
          transferTrucks.map((transferTruck) => ({
            ...transferTruck,
            quantity: !!transferTruck.quantity ? transferTruck.quantity : 0,
          })),
        equipmentPieces: (equipmentPieces) =>
          equipmentPieces.map((equipment) => ({
            ...equipment,
            timeOnJob: convertInputHoursToSeconds(equipment.timeOnJob),
            timeTravel: convertInputHoursToSeconds(equipment.timeTravel),
          })),
        crews: (crews) =>
          crews.map((crew) => ({
            ...crew,
            timeOnjob: convertInputHoursToSeconds(crew.timeOnjob),
            timeTravel: convertInputHoursToSeconds(crew.timeTravel),
          })),
        overallTotalOverride: (overallTotalOverride) =>
          !!overallTotalOverride ? overallTotalOverride : null,
        transferTruckLoadTimeBuffer: (transferTruckLoadTimeBuffer) =>
          transferTruckLoadTimeBuffer ? +(transferTruckLoadTimeBuffer / 60) : 0,
        transferTruckUnloadTimeBuffer: (transferTruckUnloadTimeBuffer) =>
          transferTruckUnloadTimeBuffer ? +(transferTruckUnloadTimeBuffer / 60) : 0,
      })(input);

      const result = await updateProposalProduct({
        variables: { input: inputWithTransformedNullValues },
      });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        setProductFormStatus(product.id, "fulfilled");
        // writeProposal(result.data.updateProposalProduct as Proposal);
      } else {
        setProductFormStatus(product.id, "rejected");
        // alert that it failed to update
        show({
          message: getLocalString("proposals.update-proposal-product-failure"),
          options: { variant: "error" },
        });
      }
      return { success, data };
    },
    [updateProposalProduct, setProductFormStatus]
  );

  useEffect(() => {
    if (isDirty && expandedProduct !== product.id) {
      //@ts-ignore
      methods.handleSubmit(onSubmit)();
    }
  }, [isDirty, expandedProduct, product.id, methods.handleSubmit, onSubmit]);
  useEffect(() => {
    if (product.mapImageDirty) {
      mapImageInterval.current = setInterval(() => {
        //@ts-ignore
        methods.handleSubmit(onSubmit)();
      }, 60 * 1000);
      // @ts-ignore
      return () => mapImageInterval.current && clearInterval(mapImageInterval.current);
    }
  }, [product.mapImageDirty]);

  useEffect(() => {
    // Let's update the submitFns for this product id
    //@ts-ignore
    setProductSubmitFunction(product.id, methods.handleSubmit(onSubmit));
  }, [product, methods.handleSubmit, onSubmit]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const transferTrucksById = transferTrucks.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    };
  }, {});

  const saveMapValue = (data: MapOutput) => {
    if (product?.material?.measurementJob?.trim() === ProductMeasurementJob.UNIT) {
      return data?.measurements?.area?.feet ?? data?.measurements?.area?.yards ?? 0;
    }
    const [measurement, unit] =
      measurementMapTranslations[product.material.measurementJob?.trim()]?.split(".");
    return data.measurements[measurement]?.[unit] ?? 0;
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = e.which || e.keyCode;
    if (
      (e.key.toLowerCase() === "enter" || keyCode?.toString() === "13") &&
      e.target["role"] !== "textbox"
    ) {
      setProductDirtyStatus(product.id, false);
      //@ts-ignore
      methods.handleSubmit(onSubmit)();
    }
  };
  return (
    <StageProductContext.Provider
      //@ts-ignore
      value={{ product, stage, defaultValues, onSubmit, transferTrucksById }}
    >
      <FormProvider {...methods}>
        <DirtyFieldsTracker id={product.id} />
        <MDBox
          component="form"
          role="form"
          onSubmit={
            //@ts-ignore
            methods.handleSubmit(onSubmit)
          }
          onKeyDown={onEnterPress}
        >
          <MDBox>
            <Grid
              container
              my={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={3}>
                <MDBox display="flex" alignItems="center">
                  <FormField
                    label="Enter Job Size"
                    disabled={inViewMode}
                    shrink
                    {...register("jobSize", {
                      setValueAs: (v) => (v ? parseInt(v?.toString().replace(",", ""), 10) : v), // remove commas in input
                    })}
                  />
                  <MDTypography px={1} variant="body2">
                    {measurementJobTranslations[product.material.measurementJob]}
                  </MDTypography>
                </MDBox>
              </Grid>
              {!inViewMode && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  display="flex"
                  justifyContent={{ xs: "center", md: "flex-end" }}
                >
                  <Controller
                    name="mapData"
                    control={methods.control}
                    render={({ field }) => (
                      <>
                        <MDButton variant="gradient" color="secondary" onClick={onOpen}>
                          Measure on Map
                        </MDButton>
                        {mapDataProduct && (
                          <MapModal
                            initialArea={mapDataProduct ? JSON.parse(mapDataProduct) : null}
                            open={open}
                            hideLocation
                            onClose={onClose}
                            showTools={[
                              product.material.measurementJob?.trim() as keyof typeof ProductMeasurementJob,
                            ]}
                            onSave={(data) => {
                              setProductDirtyStatus(product.id, false);
                              setMapDataProduct(JSON.stringify(data.geojson));
                              field.onChange(JSON.stringify(data.geojson));
                              methods.setValue("jobSize", saveMapValue(data));
                              methods.setValue("zoom", data.zoom);
                              methods.setValue("mapData", JSON.stringify(data.geojson));
                              //@ts-ignore
                              methods.handleSubmit(onSubmit)();
                            }}
                          />
                        )}
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Accordion defaultExpanded elevation={3}>
              <AccordionSummary
                sx={{ backgroundColor: "rgba(100, 100, 150, 0.1)" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MDTypography variant="h6">Proposal Defaults and Inputs</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <ProposalDefaultsAndInputs />
              </AccordionDetails>
            </Accordion>
            {!isSubcontracted && !isUnitPriced && (
              <>
                <Accordion defaultExpanded elevation={3}>
                  <AccordionSummary
                    sx={{ backgroundColor: "rgba(100, 100, 150, 0.1)" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MDTypography variant="h5">Material Costs</MDTypography>
                      {new Intl.NumberFormat(undefined, {
                        currency: "USD",
                        style: "currency",
                      }).format((+product.costMaterial + +product.costTransferTruck) / 100)}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MaterialCosts />
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded elevation={3}>
                  <AccordionSummary
                    sx={{ backgroundColor: "rgba(150, 150, 175, 0.1)" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MDTypography variant="h5">Equipment Costs</MDTypography>
                      <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {formatCentsToUSD(product.costEquipment)}
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EquipmentCostsSubForm />
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded elevation={3}>
                  <AccordionSummary
                    sx={{ backgroundColor: "rgba(100, 100, 150, 0.1)" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MDTypography variant="h5">Labor Costs</MDTypography>
                      <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {formatCentsToUSD(product.costLabour)}
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LabourCostsSubForm />
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded elevation={3}>
                  <AccordionSummary
                    sx={{ backgroundColor: "rgba(100, 100, 150, 0.1)" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MDTypography variant="h5">Additional Costs</MDTypography>
                      <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {formatCentsToUSD(product.costAdditional)}
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AdditionalCostsSubForm />
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            <MDBox pb={2}>
              <MDBox display="flex" justifyContent="space-between" py={2}>
                <MDTypography mb={2} variant="h6">
                  Product Files
                </MDTypography>
                <AddProjectFileToProposal id={product.id} />
              </MDBox>
              <MDBox>
                <ProposalProductFiles />
              </MDBox>
            </MDBox>
            <Grid item xs={12} my={0}>
              <MDTypography variant="h6" my={0}>
                Product Summary
              </MDTypography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={measurementJobUnit && !isUnitPriced ? 4 : 6} my={1}>
                  <FormField
                    label="Desired Profit Margin %"
                    placeholder={product.profitPercentage}
                    disabled={inViewMode || !!getValues("overallTotalOverride") || isSubcontracted}
                    type="number"
                    inputProps={{ min: 1, step: 0.01 }}
                    {...register("profitPercentageOverride", { valueAsNumber: true })}
                  />
                </Grid>

                {measurementJobUnit && !isUnitPriced && (
                  <Grid item xs={12} md={4}>
                    <CurrencyFormField
                      label="Unit Price"
                      placeholder={+product.material.costMaterial / 100}
                      disabled={inViewMode || !!getValues("material.unitPrice") || isSubcontracted}
                      name="material.unitPrice"
                      control={control}
                      {...currencyEndAdornment}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={measurementJobUnit && !isUnitPriced ? 4 : 6}>
                  <CurrencyFormField
                    label="Overall Total"
                    placeholder={+product.overallTotal / 100}
                    disabled={inViewMode || !!getValues("profitPercentageOverride")}
                    name="overallTotalOverride"
                    control={control}
                    {...currencyEndAdornment}
                    key={isSubmitSuccessful}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        sx={{ backgroundColor: "rgba(150, 150, 200, 0.3)" }}
                      >
                        Product Summary
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Total Costs
                      </TableCell>
                      <TableCell align="right">Profit</TableCell>
                      <TableCell align="right">Profit Margin %</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {formatCentsToUSD(+product.costTotal)}
                      </TableCell>
                      <TableCell align="right">{formatCentsToUSD(product.profit)}</TableCell>
                      <TableCell align="right">
                        {product?.profitPercentageOverride ?? product.profitPercentage}
                      </TableCell>
                      <TableCell align="right">
                        {formatCentsToUSD(
                          product.overallTotalOverride
                            ? +product.overallTotalOverride
                            : +product.overallTotal
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </MDBox>
          {!inViewMode && (
            <MDBox
              textAlign="right"
              sx={{ display: "flex", justifyContent: "flex-end", gap: 1, paddingTop: "10px" }}
            >
              <MDButton type="submit" variant="gradient" color="success">
                Save Stage Product
              </MDButton>
              <DeleteStageProduct
                acceptFn={() => (handleDelete ? handleDelete(product.id) : () => {})}
              />
            </MDBox>
          )}
        </MDBox>
      </FormProvider>
    </StageProductContext.Provider>
  );
});

export default StageProduct;
