import { useState, useEffect } from "react";
import Modal from "modules/Modal/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import View from "modules/jobs/View";
import { Job } from "generated/graphql";
import { Box } from "@mui/material";
import MDTypography from "components/MDTypography";

interface JobModalProps {
  jobId: string | null;
  open: boolean;
  onClose: () => void;
}

export default function JobModal({ jobId, open, onClose }: JobModalProps) {
  const [jobSelected, setJobSelected] = useState<Job | null>(null);
  const [getJob] = useGetJobLazy();

  useEffect(() => {
    const fetchData = async () => {
      if (jobId && open) {
        const jobInfo = await getJob(jobId);
        setJobSelected(jobInfo?.data?.getJob as Job);
      } else {
        setJobSelected(null);
      }
    };

    fetchData();
  }, [jobId]);

  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose} styles={{ maxWidth: "auto" }}>
      <Box sx={{ p: 3, mx: "auto" }}>
        <MDTypography variant="h4" fontWeight="medium" mb={1}>
          <a
            href={`/jobs/${jobId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit" }}
          >
            Update Job # {jobId}
          </a>
        </MDTypography>
        {jobSelected ? (
          <View job={jobSelected as Job} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="65vh">
            <CircularProgress color="secondary" size={110} />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
