import getLocalString from "constants/Localization";
import { useUpdateOrganizationProposalDefaultsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateOrganizationProposalDefaults() {
  const [mutation, { loading, data, error }] = useUpdateOrganizationProposalDefaultsMutation();
  const [updateOrganizationProposalDefaults, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateOrganizationProposalDefaults",
    },
    {
      successMessage: getLocalString("organization.update-organization-proposal-defaults-success"),
      failureMessage: getLocalString("organization.update-organization-proposal-defaults-failure"),
      showValidationErrors: true,
    }
  );

  return [updateOrganizationProposalDefaults, context] as const;
}
