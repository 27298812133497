import { Invoice, Job, Organization, Proposal } from "generated/graphql";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import SendInvoiceModal from "../Modals/SendInvoiceModal";
import useGetInvoiceLazy from "hooks/organization/invoices/useGetInvoiceLazy";
import { useEffect } from "react";

interface SendInvoiceProps {
  job: Job;
  proposalId: Proposal["id"];
  organization: Organization;
}

export default function SendInvoice({ job, proposalId, organization }: SendInvoiceProps) {
  const {
    open: sendInvoiceOpen,
    onClose: onCloseSendInvoice,
    onOpen: onOpenSendInvoice,
  } = useModalState();

  const [getInvoice, { loading, invoice }] = useGetInvoiceLazy();

  useEffect(() => {
    if (sendInvoiceOpen) {
      getInvoice({ id: job.invoiceId });
    }
  }, [sendInvoiceOpen, job.invoiceId, getInvoice]);

  return (
    <>
      <MDButton color="success" onClick={onOpenSendInvoice}>
        Send Invoice
      </MDButton>

      <Modal
        open={sendInvoiceOpen}
        onClose={onCloseSendInvoice}
        styles={{ overflow: "auto", height: "90vh" }}
      >
        <SendInvoiceModal
          open={sendInvoiceOpen}
          onClose={onCloseSendInvoice}
          proposalId={proposalId}
          job={job}
          organization={organization}
          invoice={invoice as Invoice}
          loading={loading}
        />
      </Modal>
    </>
  );
}
