import { Stack } from "@mui/material";
import MDButton from "components/MDButton";
import { Job, OrganizationLinkedServiceName, Proposal, Organization } from "generated/graphql";
import useGetOrganization from "hooks/organization/useGetOrganization";
import CreateChangeOrder from "modules/proposals/buttons/CreateChangeOrder/CreateChangeOrder";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import useModalState from "hooks/useModalState";
import QuickbooksConfirmationModal from "./Modals/QuickbooksConfirmationModal";
import SendInvoice from "./SendInvoice/SendInvoice";

export default function StackSection({
  proposalId,
  jobId,
  job,
  shouldPushTaxesToQuickbooks,
  proposal,
  mayShowTaxNotification,
}: {
  proposalId: Proposal["id"];
  jobId: Job["id"];
  job: Job;
  shouldPushTaxesToQuickbooks?: Boolean;
  mayShowTaxNotification?: Boolean;
  proposal: Partial<Proposal>;
}) {
  const { data } = useGetOrganization();
  const {
    open: quickbooksOpen,
    onClose: onCloseQuickbooks,
    onOpen: onOpenQuickbooks,
  } = useModalState();

  const hasLinkedQuickbooksDesktop = !!data?.linkedServices?.find(
    (service) =>
      service.service === OrganizationLinkedServiceName.QUICKBOOKS_DESKTOP && service.active
  );

  const hasLinkedQuickbooksOnline = !!data?.linkedServices?.find(
    (service) => service.service === OrganizationLinkedServiceName.QUICKBOOKS && service.active
  );

  return (
    <>
      <Stack direction="row" spacing={5} justifyContent="center">
        {(hasLinkedQuickbooksOnline || hasLinkedQuickbooksDesktop) && (
          <MDButton color="success" onClick={onOpenQuickbooks}>
            Sync with Quickbooks
          </MDButton>
        )}
        {data && (
          <SendInvoice job={job} proposalId={proposalId} organization={data as Organization} />
        )}
        <MDButton component={Link} to={`/proposals/${proposalId}`} color="success">
          View Proposal
        </MDButton>
        <MDButton
          component={Link}
          to={`/jobs/schedule?jobId=${jobId}`}
          variant="contained"
          color="success"
        >
          Schedule
        </MDButton>
        <CreateChangeOrder
          isChangeOrder={proposal.isChangeOrder}
          status={proposal.status}
          id={proposal.id}
          changeOrderJobId={jobId}
        />
      </Stack>

      <Modal
        open={quickbooksOpen}
        onClose={onCloseQuickbooks}
        styles={{ overflow: "auto", height: "90vh" }}
      >
        <QuickbooksConfirmationModal
          proposalId={proposalId}
          job={job}
          organization={data as Organization}
          shouldPushTaxesToQuickbooks={shouldPushTaxesToQuickbooks}
          mayShowTaxNotification={mayShowTaxNotification}
        />
      </Modal>
    </>
  );
}
