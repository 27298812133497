import { GetInvoiceQueryVariables, useGetInvoiceLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetInvoiceLazy() {
  const [query, { data, loading, error }] = useGetInvoiceLazyQuery();

  const getInvoice = useCallback(
    (values: GetInvoiceQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const invoice = useMemo(() => {
    return (data && data.getInvoice) || null;
  }, [data]);

  return [getInvoice, { data, loading, error, invoice }] as const;
}
