import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { schema, getDefaultValues } from "DDD/action-objects/ProposalStageUpdate";
import { CustomUpdateProposalStageMutationVariables, ProposalStage } from "generated/graphql";
import useUpdateProposalStageAddress from "hooks/proposals/useUpdateProposalStageAddress";
import useModalState from "hooks/useModalState";
import MapModal from "modules/Mapmodal/MapModal";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import humanReadableAddress from "utils/address/humanReadableAddress";
import { useSearchParams } from "react-router-dom";
import DeleteStage from "./buttons/Stage/DeleteStage";

type PendingStageFormProps = CustomUpdateProposalStageMutationVariables & { address?: string };

export default function PendingStage({
  stage,
  onDeleteStage,
}: {
  stage: ProposalStage;
  onDeleteStage?: (id: ProposalStage["id"]) => void;
}) {
  const [updateProposalStageAddress] = useUpdateProposalStageAddress();

  const { open: mapDataIsOpen, onClose: closeMapData, onOpen: showMapData } = useModalState();

  const { watch, setValue, formState, getValues, control, handleSubmit, register } =
    useForm<PendingStageFormProps>({
      resolver: zodResolver(schema),
      defaultValues: getDefaultValues({ id: stage.id }),
    });

  const stageName = watch("name");

  const onSubmit = useCallback(
    async (variables: CustomUpdateProposalStageMutationVariables & { address: string }) => {
      const { success, data } = await updateProposalStageAddress(variables);
      return { success, data };
    },
    [updateProposalStageAddress]
  );

  const [getSearchParams] = useSearchParams();
  useEffect(() => {
    const stageName = getSearchParams.get("stageName");
    if (stageName) {
      setValue("name", stageName);
    }
  }, []);

  const { errors } = formState;

  return (
    <>
      <div>
        <MDTypography variant="h6" mt={3}>
          Stage Name:{" "}
          {isNotNilOrEmpty(stage.name)
            ? stage.name
            : isNotNilOrEmpty(stageName)
            ? stageName
            : "(Type Stage Name)"}
        </MDTypography>
        <MDBox p={3} component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <input type="hidden" {...register("id")} />
            <Grid item xs={12} md={6}>
              <FormField label="Enter Stage Name" {...register("name")} error={errors.name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <>
                    <div onClick={showMapData}>
                      <FormField
                        label="Enter Stage Address"
                        disabled
                        value={
                          field.value
                            ? getValues("addressOverride") || humanReadableAddress(field.value)
                            : ""
                        }
                        error={errors.address}
                      />
                    </div>
                    <MapModal
                      open={mapDataIsOpen}
                      onClose={closeMapData}
                      guided
                      onSave={(data) => {
                        setValue("mapData", JSON.stringify(data.geojson));
                        setValue("addressOverride", data.addressOverride);
                        field.onChange(JSON.stringify(data.address));
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <MDButton variant="gradient" color="info" type="submit">
                Continue
              </MDButton>
              <DeleteStage acceptFn={() => (onDeleteStage ? onDeleteStage(stage.id) : "")} />
            </Grid>
          </Grid>
        </MDBox>
      </div>
    </>
  );
}
