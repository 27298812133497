import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import getLocalString from "constants/Localization";
import useInvoicesTable from "hooks/invoices/useInvoicesTable";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";

export default function InvoicesPage() {
  const { tableData, loading, pagination, filtering, sorting } = useInvoicesTable();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h4">Invoices</MDTypography>
                </MDBox>
              </MDBox>
              <DataTable
                canSearch
                table={tableData}
                showTotalEntries={false}
                pagination={pagination}
                filtering={filtering}
                sorting={sorting}
                isSorted={true}
              />
              {tableData.modal}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
