import { Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import { useState } from "react";

interface InvoiceEmailEditorProps {
  content: string;
  loading: boolean;
  onSubmit: (content: string) => Promise<void>;
}

export default function InvoiceEmailEditor({
  content,
  loading,
  onSubmit,
}: InvoiceEmailEditorProps) {
  const [emailContent, setEmailContent] = useState(content);

  return (
    <Paper sx={{ p: "25px", mb: "20px" }}>
      <MDBox>
        <WYSIWYG
          type="invoice"
          content={emailContent}
          onChange={setEmailContent}
          title="Invoice Email Copy"
          tooltip="This copy will be used when sending invoices"
        />
        <MDBox display="flex" justifyContent="flex-end" mt={2}>
          <MDButton
            variant="gradient"
            color="success"
            onClick={() => onSubmit(emailContent)}
            disabled={loading}
          >
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Paper>
  );
}
