import ProposalActionsCell from "components/proposals/ProposalActionsCell";
import { useGetProposals } from "hooks/companies/useGetProposals";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { useCallback, useMemo, useState } from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import dayjs from "dayjs";
import {
  CustomGetProposalsQueryVariables,
  GetProposalsSortKey,
  ProposalStatus,
  SortDirection,
  Proposal,
} from "generated/graphql";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { equals, reject, uniq } from "ramda";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { capitalCase } from "change-case";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import SingleChoiceFilter from "components/filters/SingleChoiceFilter/SingleChoiceFilter";
import { Link } from "react-router-dom";
import { Cell } from "react-table";

export const proposalsSortKeyMap = {
  id: GetProposalsSortKey.ID,
  "company.name": GetProposalsSortKey.COMPANY,
  status: GetProposalsSortKey.STATUS,
  createdAt: GetProposalsSortKey.CREATED,
  sentAt: GetProposalsSortKey.SENT,
  overallTotal: GetProposalsSortKey.TOTAL,
  default: GetProposalsSortKey.CREATED,
};

export const proposalsInitialSortDirectionKey = "desc";
export const proposalsInitialSortDirectionValue = SortDirection.DESC;
export const proposalsInitialSortKeyKey = "createdAt";
export const proposalsInitialSortKeyValue = GetProposalsSortKey.CREATED;

export default function useProposalsTable({
  companyId,
}: {
  companyId?: string;
} = {}) {
  const { filtering, offset, sorting } = usePaginatedVariables({
    initialSortDirection: proposalsInitialSortDirectionKey,
    initialSortKey: proposalsInitialSortKeyKey,
  });
  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies(true);
  const [companyFilterId, setCompanyFilterId] = useState<string | null>(null);

  const params: CustomGetProposalsQueryVariables = {
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sortKey: proposalsSortKeyMap[sorting.sortKey] || proposalsInitialSortKeyValue,
    sortDirection: sortDirectionMap[sorting.sortDirection] || proposalsInitialSortDirectionValue,
    statuses: filtering.statuses as ProposalStatus[],
    companyId: companyFilterId ?? null,
  };

  if (companyId) {
    params.companyId = companyId;
  }

  const { data, loading, error, paginatorInfo } = useGetProposals(params);

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const handleStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setStatuses(selectedOptions);
    },
    [filtering]
  );

  const statusFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.statuses}
          options={enumToValueOptions(ProposalStatus)}
          onChange={handleStatusChecked}
        />
      );
    },
    [filtering.statuses]
  );

  const companyFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <SingleChoiceFilter
          id={id}
          value={companyFilterId}
          loading={companiesLoading}
          error={companiesError}
          options={
            companiesData?.map((company) => ({ label: company.name, value: company.id })) ?? []
          }
          onChange={(selectedCompanyId) =>
            setCompanyFilterId((prevState) =>
              prevState == selectedCompanyId ? null : selectedCompanyId
            )
          }
        />
      );
    },
    [companyFilterId, companiesData, companiesLoading, companiesError]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        Cell: ({ value, row }) => (
          <Link style={{ color: "inherit" }} to={`/proposals/${value}`}>
            {row.original.externalIdWithPrefix}
          </Link>
        ),
      },
      {
        Header: "Client Name",
        accessor: "company.name",
        Filter: companyFilter,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => capitalCase(value),
        Filter: statusFilter,
      },
      {
        Header: "Contact Name",
        accessor: "contact.firstName",
        Cell: ({ row, value }: Cell<Proposal>) =>
          row.original.contact.firstName + " " + row.original.contact.lastName,
      },
      {
        Header: "Contact Phone",
        accessor: "contact.phone",
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        disableFilters: true,
        Cell: ({ value }) => (value ? dayjs(value).format("YYYY-MM-DD") : "-"),
      },
      {
        Header: "Sent Date",
        accessor: "sentAt",
        disableFilters: true,
        Cell: ({ value }) => (value ? dayjs(value).format("YYYY-MM-DD") : "-"),
      },
      {
        Header: "Proposal Total",
        accessor: "overallTotal",
        align: "right",
        disableFilters: true,
        Cell: ({ value }) => formatCentsToUSD(value),
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "right",
        disableSortBy: true,
        disableFilters: true,
        Cell: ProposalActionsCell,
      },
    ];
  }, [statusFilter, companyFilter]);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
    sorting,
  } as const;
}
