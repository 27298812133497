import {
  GetProposalProductMapDataQueryVariables,
  useGetProposalProductMapDataLazyQuery,
  useGetProposalProductMapDataQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetProposalProductMapData({
  proposalProductId,
  token,
}: GetProposalProductMapDataQueryVariables) {
  const { data, loading, error } = useGetProposalProductMapDataQuery({
    variables: { proposalProductId, token },
  });
  const mapData = useMemo(() => {
    return data?.getProposalProductMapData?.mapData;
  }, [data]);

  return {
    data,
    loading,
    error,
    mapData,
    zoom: data?.getProposalProductMapData?.zoom || 5,
  } as const;
}
