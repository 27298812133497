import getLocalString from "constants/Localization";
import { usePreviewInvoiceMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function usePreviewInvoice() {
  const [mutation, { loading, error, data }] = usePreviewInvoiceMutation();

  const [previewInvoice, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "previewInvoice",
    },
    {
      successMessage: getLocalString("organization.preview-invoice-success"),
      failureMessage: getLocalString("organization.preview-invoice-failure"),
      showValidationErrors: true,
    }
  );

  return [previewInvoice, context] as const;
}
