import "components/Map/Map.css";
import "leaflet/dist/leaflet.css";
import Map from "components/Map/Map";
import PageLayout from "layouts/PageLayout/PageLayout";
import { useEffect } from "react";
import useGetProposalProductMapData from "hooks/proposals/useGetProposalProductMapData";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function PublicMapPage() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  if (!searchParams.get("token") && !searchParams.get("proposalProductId")) {
    navigate("/");
  }
  const { error, loading, mapData, zoom } = useGetProposalProductMapData({
    token: searchParams.get("token"),
    proposalProductId: searchParams.get("proposalProductId"),
  });
  // const win = window as any;

  // Don't display anything without correct data
  if (error || loading || !mapData) {
    return null;
  }

  // if (!error && !loading && mapData) {
  //   win.retrieveMapData = () => {};
  // }
  return (
    <PageLayout id="map-layout">
      <Map
        onSave={null}
        readonly
        createIslandConfirmation={() => false}
        initialArea={null}
        initialFeatures={mapData ? JSON.parse(mapData) : null}
        initialCenter={null}
        guidedControls={false}
        goBack
        isTouch={false}
        initialZoom={zoom}
      />
    </PageLayout>
  );
}
