import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { Proposal } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import VersionActionsCell from "./VersionActionsCell";
import MDTypography from "components/MDTypography";
import DuplicateProposal from "../buttons/DuplicateProposal/DuplicateProposal";

export default function Versions({ proposal }: { proposal: Proposal }) {
  const showMarkAsClosedWon = !["CLOSED_WON", "CLOSED_LOST"].includes(proposal.status);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <MDTypography variant="h5" my={2}>
            Versions
          </MDTypography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          {!["CLOSED_WON", "CLOSED_LOST"].includes(proposal.status) && (
            <DuplicateProposal
              id={proposal.id}
              status={proposal.status}
              variables={{ createNewVersion: true }}
              text="Create New Version"
              normalText={true}
              useIcon={true}
              redirectRoute="proposals.info"
            />
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{
            [`& .${tableCellClasses.head}`]: {
              backgroundColor: "#666",
              color: "#fff",
            },
            [`& .${tableCellClasses.body}`]: {
              fontSize: 14,
            },
            maxWidth: "100%",
          }}
        >
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposal.versions.map((version) => (
              <TableRow
                key={version.version}
                sx={{ background: version.activeVersion ? "#dedede" : "unset" }}
              >
                <TableCell>{version.version}</TableCell>
                <TableCell>{version.status}</TableCell>
                <TableCell>{formatCentsToUSD(version.overallTotalAfterTaxes)}</TableCell>
                <TableCell>
                  <VersionActionsCell
                    row={{ values: version }}
                    proposalIsWon={proposal.status === "CLOSED_WON"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
